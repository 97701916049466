



















import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { SignDigitalSignatureViewModelGen } from "@/services/sign/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { SignModule } from "@/store/modules/sign.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import DocumentDetailCard from "./DocumentDetailCard.vue";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";

@Component({
  components: {
    TheLayoutPortal,
    LatestEntriesCardEmpty,
    DocumentDetailCard
  },
  filters: { simpleDoubleDigitDate }
})
export default class DocumentDetail extends mixins(PartnerFallbackMixin, DocumentDetailMixin) {
  isLoading = true;

  document: ISignDocument | null = null;
  signature: SignDigitalSignatureViewModelGen | null = null;

  async mounted() {
    const partnerId = this.$route.params.partnerId;
    const documentId = this.$route.params.documentId;

    try {
      this.isLoading = true;

      await this.trySetByRouteOrDefault();

      if (partnerId && documentId) {
        const signDocument = await new SignDocument({ partnerId, id: documentId }).fetch();
        this.document = signDocument;

        if (signDocument.isSigned) {
          if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.SIGN, PartnerModule.partner.id))
            this.signature = await SignModule.findOneByDocument({ partnerId, documentId });
        }
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
